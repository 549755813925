<template>
  <div class="row">

    <Print/>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Stock-opname ' + data.checking_date"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="1000px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print/>
      </section>
    </vue-html2pdf>

    <div class="col-md">
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="info"
        @click="btnDownloadOnClick"
      >Cetak</b-button>
    </div>

  </div>
</template>

<script>

import Print from '@/component/stock-opname-report/Print.vue'
import VueHtml2pdf from "vue-html2pdf"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Print,
    VueHtml2pdf
  },

  data() {
    return {
      data : []
    }
  },

  methods: {

    async getDate() {
      let res = await module.get(`stock-opnames/${this.$route.params.id}`)
      this.data = res
    },

    print(){
      this.$htmlToPaper('print')
    },

    btnDownloadOnClick() {
      
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock Opname" },
      { title: "Daftar Stock Opname", route: "/stock-opnames/list" },
      { title: "Print" },
    ])

    this.getDate()
  },

}
</script>

<style>
</style>